import React, { Component } from 'react'
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import { get, first } from 'lodash'
import { getDataForLanguage} from '../utils/getDataForLanguage'

export class ShareYourStory extends Component {
    render() {

        const allPageData = get(this, "props.data.allContentfulSiteInformations.edges", '');
        let pageData = first(getDataForLanguage(allPageData.map(item => item.node), process.env.COUNTRY));

        return (
            <Layout
                title={get(pageData, 'indexPageMetaTitle','')} 
                description={get(pageData, 'indexPageMetaDescription','')}
                pathName="/"
            >
                <div className="basic-page" style={{backgroundColor: "black", color: "white"}}>
                    <div className="container" id="maincontent">
                        <h1 className="page-title text-center">Share Your Story</h1>
                        <form className="contact-form--modal" name="Share Your Story Form" method="POST" data-netlify="true" action="/success">
                            <div className="contact-form--modal__description">
                                Have you found relief with O’Keeffe’s? Share your story with us!
                            </div>
                            <input type="hidden" name="form-name" value="Share Your Story Form" />
                            <div className="field">
                                <label>Name</label>
                                <input type="text" name="name" required />
                            </div>
                            <div className="field">
                                <label>Email</label>
                                <input type="email" name="email" required />
                            </div>
                            <div className="field">
                                <label>Phone</label>
                                <input type="text" name="phone" />
                            </div>
                            <div className="field">
                                <label>Story</label>
                                <textarea name="message" />
                            </div>
                            <div className="field stacked">
                                <label>I agree to have my testimonial shared on social media</label>
                                <input type="checkbox" name="share" value="yes" />
                            </div>
                            <div data-netlify-recaptcha="true"></div>
                            <button type="submit" className="btn btn--primary">Submit</button>
                        </form>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default ShareYourStory


export const pageQuery = graphql`
    query SiteInfoForShareYourStory {
        allContentfulSiteInformations {
            edges {
                node {
                    indexPageMetaTitle
                    indexPageMetaDescription
                    node_locale
                    contentful_id
                }
            }
        }
    }
`
